import GoogleCustomerSurvey from '~google-customer-survey/class'

export default (ctx, inject) => {
  /**
   * @type {GoogleCustomerSurveyModuleOptions | undefined }
   */
  const parsedOptions = JSON.parse('{"debug":false,"disabled":false,"loadTimeout":5000,"merchantId":166737417}')

  /**
   * @type GoogleCustomerSurveyModuleOptions
   */
  const options = Object.assign({}, parsedOptions)

  const instance = new GoogleCustomerSurvey(options)

  ctx.$googleCustomerSurvey = instance
  inject('googleCustomerSurvey', instance)
}
