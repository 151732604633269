import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1d4b0d3b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _37092ddb = () => interopDefault(import('../pages/5-reasons-why-alao.vue' /* webpackChunkName: "pages/5-reasons-why-alao" */))
const _74c2cc0f = () => interopDefault(import('../pages/coverage.vue' /* webpackChunkName: "pages/coverage" */))
const _0258f6c2 = () => interopDefault(import('../pages/ratings.vue' /* webpackChunkName: "pages/ratings" */))
const _ff20f2d2 = () => interopDefault(import('../pages/black-friday/index.vue' /* webpackChunkName: "pages/black-friday/index" */))
const _3b023caf = () => interopDefault(import('../pages/black-september/index.vue' /* webpackChunkName: "pages/black-september/index" */))
const _4689d606 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _020b900e = () => interopDefault(import('../pages/document-proof.vue' /* webpackChunkName: "pages/document-proof" */))
const _a971a394 = () => interopDefault(import('../pages/get-advice.vue' /* webpackChunkName: "pages/get-advice" */))
const _a8ab8d84 = () => interopDefault(import('../pages/b2b/index.vue' /* webpackChunkName: "pages/b2b/index" */))
const _837484da = () => interopDefault(import('../pages/iframe-offer-card.vue' /* webpackChunkName: "pages/iframe-offer-card" */))
const _52edca52 = () => interopDefault(import('../pages/learn_more.vue' /* webpackChunkName: "pages/learn_more" */))
const _7d57ac76 = () => interopDefault(import('../pages/offer.vue' /* webpackChunkName: "pages/offer" */))
const _57ae220c = () => interopDefault(import('../pages/order/index.vue' /* webpackChunkName: "pages/order/index" */))
const _4839997c = () => interopDefault(import('../pages/quiz.vue' /* webpackChunkName: "pages/quiz" */))
const _d4a28ff6 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _cbd8520c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _c6400738 = () => interopDefault(import('../pages/video.vue' /* webpackChunkName: "pages/video" */))
const _16b90538 = () => interopDefault(import('../pages/how-it-works.vue' /* webpackChunkName: "pages/how-it-works" */))
const _b502506c = () => interopDefault(import('../pages/black-friday/mobile-plans.vue' /* webpackChunkName: "pages/black-friday/mobile-plans" */))
const _7a3092e9 = () => interopDefault(import('../pages/black-friday/internet-plans.vue' /* webpackChunkName: "pages/black-friday/internet-plans" */))
const _4d261eb6 = () => interopDefault(import('../pages/special-offers/request-cashback.vue' /* webpackChunkName: "pages/special-offers/request-cashback" */))
const _50bd2216 = () => interopDefault(import('../pages/compare/bundle/index.vue' /* webpackChunkName: "pages/compare/bundle/index" */))
const _0df24b94 = () => interopDefault(import('../pages/compare/mobile/index.vue' /* webpackChunkName: "pages/compare/mobile/index" */))
const _3c4dc073 = () => interopDefault(import('../pages/compare/home/index.vue' /* webpackChunkName: "pages/compare/home/index" */))
const _05648f26 = () => interopDefault(import('../pages/events/birthday/register.vue' /* webpackChunkName: "pages/events/birthday/register" */))
const _72752c56 = () => interopDefault(import('../pages/b2b/fallstudie/apartment-service.vue' /* webpackChunkName: "pages/b2b/fallstudie/apartment-service" */))
const _62aba5a0 = () => interopDefault(import('../pages/b2b/fallstudie/kern-studer.vue' /* webpackChunkName: "pages/b2b/fallstudie/kern-studer" */))
const _bbc6f19e = () => interopDefault(import('../pages/b2b/fallstudie/sanoptis.vue' /* webpackChunkName: "pages/b2b/fallstudie/sanoptis" */))
const _1b04b4d4 = () => interopDefault(import('../pages/order/update/selfie.vue' /* webpackChunkName: "pages/order/update/selfie" */))
const _62b3f77b = () => interopDefault(import('../pages/compare/mobile/5g-plans.vue' /* webpackChunkName: "pages/compare/mobile/5g-plans" */))
const _589c50d6 = () => interopDefault(import('../pages/compare/mobile/deal-plans.vue' /* webpackChunkName: "pages/compare/mobile/deal-plans" */))
const _c5717376 = () => interopDefault(import('../pages/compare/mobile/ch-unlimited.vue' /* webpackChunkName: "pages/compare/mobile/ch-unlimited" */))
const _0db9e84b = () => interopDefault(import('../pages/compare/mobile/europe-plans.vue' /* webpackChunkName: "pages/compare/mobile/europe-plans" */))
const _4f0680ce = () => interopDefault(import('../pages/compare/bundle/_slug.vue' /* webpackChunkName: "pages/compare/bundle/_slug" */))
const _115f8e24 = () => interopDefault(import('../pages/compare/mobile/_slug.vue' /* webpackChunkName: "pages/compare/mobile/_slug" */))
const _3a971f2b = () => interopDefault(import('../pages/compare/home/_slug.vue' /* webpackChunkName: "pages/compare/home/_slug" */))
const _b4391630 = () => interopDefault(import('../pages/amp/_slug.vue' /* webpackChunkName: "pages/amp/_slug" */))
const _12ff02d3 = () => interopDefault(import('../pages/provider/_slug.vue' /* webpackChunkName: "pages/provider/_slug" */))
const _7eb8e54f = () => interopDefault(import('../pages/black-friday/_slug.vue' /* webpackChunkName: "pages/black-friday/_slug" */))
const _49f71896 = () => interopDefault(import('../pages/blogs/_slug.vue' /* webpackChunkName: "pages/blogs/_slug" */))
const _3aa615ea = () => interopDefault(import('../pages/orders/_shortUUID.vue' /* webpackChunkName: "pages/orders/_shortUUID" */))
const _0b859d1e = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _992b5b28 = () => interopDefault(import('../pages/partner/_slug.vue' /* webpackChunkName: "pages/partner/_slug" */))
const _e5e459fa = () => interopDefault(import('../pages/offers/_type/_slug.vue' /* webpackChunkName: "pages/offers/_type/_slug" */))
const _346e9a1a = () => interopDefault(import('../pages/feedback/_type/_orderId.vue' /* webpackChunkName: "pages/feedback/_type/_orderId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de/",
    component: _1d4b0d3b,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/en/",
    component: _1d4b0d3b,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/fr/",
    component: _1d4b0d3b,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/de/5-gruende-warum-alao/",
    component: _37092ddb,
    pathToRegexpOptions: {"strict":true},
    name: "5-reasons-why-alao___de"
  }, {
    path: "/de/abdeckungskarte/",
    component: _74c2cc0f,
    pathToRegexpOptions: {"strict":true},
    name: "coverage___de"
  }, {
    path: "/de/bewertungen/",
    component: _0258f6c2,
    pathToRegexpOptions: {"strict":true},
    name: "ratings___de"
  }, {
    path: "/de/black-friday/",
    component: _ff20f2d2,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday___de"
  }, {
    path: "/de/black-september/",
    component: _3b023caf,
    pathToRegexpOptions: {"strict":true},
    name: "black-september___de"
  }, {
    path: "/de/blogs/",
    component: _4689d606,
    pathToRegexpOptions: {"strict":true},
    name: "blogs___de"
  }, {
    path: "/de/document-proof/",
    component: _020b900e,
    pathToRegexpOptions: {"strict":true},
    name: "document-proof___de"
  }, {
    path: "/de/get-advice/",
    component: _a971a394,
    pathToRegexpOptions: {"strict":true},
    name: "get-advice___de"
  }, {
    path: "/de/handy-und-internet-abos-fuer-geschaeftskunden/",
    component: _a8ab8d84,
    pathToRegexpOptions: {"strict":true},
    name: "b2b___de"
  }, {
    path: "/de/iframe-offer-card/",
    component: _837484da,
    pathToRegexpOptions: {"strict":true},
    name: "iframe-offer-card___de"
  }, {
    path: "/de/mehr_erfahren/",
    component: _52edca52,
    pathToRegexpOptions: {"strict":true},
    name: "learn_more___de"
  }, {
    path: "/de/offer/",
    component: _7d57ac76,
    pathToRegexpOptions: {"strict":true},
    name: "offer___de"
  }, {
    path: "/de/order/",
    component: _57ae220c,
    pathToRegexpOptions: {"strict":true},
    name: "order___de"
  }, {
    path: "/de/quiz/",
    component: _4839997c,
    pathToRegexpOptions: {"strict":true},
    name: "quiz___de"
  }, {
    path: "/de/ueber-uns/",
    component: _d4a28ff6,
    pathToRegexpOptions: {"strict":true},
    name: "about-us___de"
  }, {
    path: "/de/unsubscribe/",
    component: _cbd8520c,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___de"
  }, {
    path: "/de/video/",
    component: _c6400738,
    pathToRegexpOptions: {"strict":true},
    name: "video___de"
  }, {
    path: "/de/wie-funktioniert-es/",
    component: _16b90538,
    pathToRegexpOptions: {"strict":true},
    name: "how-it-works___de"
  }, {
    path: "/en/5-reasons-why-alao/",
    component: _37092ddb,
    pathToRegexpOptions: {"strict":true},
    name: "5-reasons-why-alao___en"
  }, {
    path: "/en/about-us/",
    component: _d4a28ff6,
    pathToRegexpOptions: {"strict":true},
    name: "about-us___en"
  }, {
    path: "/en/black-friday/",
    component: _ff20f2d2,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday___en"
  }, {
    path: "/en/black-september/",
    component: _3b023caf,
    pathToRegexpOptions: {"strict":true},
    name: "black-september___en"
  }, {
    path: "/en/blogs/",
    component: _4689d606,
    pathToRegexpOptions: {"strict":true},
    name: "blogs___en"
  }, {
    path: "/en/coverage/",
    component: _74c2cc0f,
    pathToRegexpOptions: {"strict":true},
    name: "coverage___en"
  }, {
    path: "/en/document-proof/",
    component: _020b900e,
    pathToRegexpOptions: {"strict":true},
    name: "document-proof___en"
  }, {
    path: "/en/get-advice/",
    component: _a971a394,
    pathToRegexpOptions: {"strict":true},
    name: "get-advice___en"
  }, {
    path: "/en/how-it-works/",
    component: _16b90538,
    pathToRegexpOptions: {"strict":true},
    name: "how-it-works___en"
  }, {
    path: "/en/iframe-offer-card/",
    component: _837484da,
    pathToRegexpOptions: {"strict":true},
    name: "iframe-offer-card___en"
  }, {
    path: "/en/learn_more/",
    component: _52edca52,
    pathToRegexpOptions: {"strict":true},
    name: "learn_more___en"
  }, {
    path: "/en/offer/",
    component: _7d57ac76,
    pathToRegexpOptions: {"strict":true},
    name: "offer___en"
  }, {
    path: "/en/order/",
    component: _57ae220c,
    pathToRegexpOptions: {"strict":true},
    name: "order___en"
  }, {
    path: "/en/quiz/",
    component: _4839997c,
    pathToRegexpOptions: {"strict":true},
    name: "quiz___en"
  }, {
    path: "/en/ratings/",
    component: _0258f6c2,
    pathToRegexpOptions: {"strict":true},
    name: "ratings___en"
  }, {
    path: "/en/unsubscribe/",
    component: _cbd8520c,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___en"
  }, {
    path: "/en/video/",
    component: _c6400738,
    pathToRegexpOptions: {"strict":true},
    name: "video___en"
  }, {
    path: "/fr/5-raisons-de-choisir-alao/",
    component: _37092ddb,
    pathToRegexpOptions: {"strict":true},
    name: "5-reasons-why-alao___fr"
  }, {
    path: "/fr/a-propos/",
    component: _d4a28ff6,
    pathToRegexpOptions: {"strict":true},
    name: "about-us___fr"
  }, {
    path: "/fr/black-friday/",
    component: _ff20f2d2,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday___fr"
  }, {
    path: "/fr/black-september/",
    component: _3b023caf,
    pathToRegexpOptions: {"strict":true},
    name: "black-september___fr"
  }, {
    path: "/fr/blogs/",
    component: _4689d606,
    pathToRegexpOptions: {"strict":true},
    name: "blogs___fr"
  }, {
    path: "/fr/comment-ca-marche/",
    component: _16b90538,
    pathToRegexpOptions: {"strict":true},
    name: "how-it-works___fr"
  }, {
    path: "/fr/couverture/",
    component: _74c2cc0f,
    pathToRegexpOptions: {"strict":true},
    name: "coverage___fr"
  }, {
    path: "/fr/document-proof/",
    component: _020b900e,
    pathToRegexpOptions: {"strict":true},
    name: "document-proof___fr"
  }, {
    path: "/fr/en_savoir_plus/",
    component: _52edca52,
    pathToRegexpOptions: {"strict":true},
    name: "learn_more___fr"
  }, {
    path: "/fr/get-advice/",
    component: _a971a394,
    pathToRegexpOptions: {"strict":true},
    name: "get-advice___fr"
  }, {
    path: "/fr/iframe-offer-card/",
    component: _837484da,
    pathToRegexpOptions: {"strict":true},
    name: "iframe-offer-card___fr"
  }, {
    path: "/fr/offer/",
    component: _7d57ac76,
    pathToRegexpOptions: {"strict":true},
    name: "offer___fr"
  }, {
    path: "/fr/order/",
    component: _57ae220c,
    pathToRegexpOptions: {"strict":true},
    name: "order___fr"
  }, {
    path: "/fr/quiz/",
    component: _4839997c,
    pathToRegexpOptions: {"strict":true},
    name: "quiz___fr"
  }, {
    path: "/fr/ratings/",
    component: _0258f6c2,
    pathToRegexpOptions: {"strict":true},
    name: "ratings___fr"
  }, {
    path: "/fr/unsubscribe/",
    component: _cbd8520c,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___fr"
  }, {
    path: "/fr/video/",
    component: _c6400738,
    pathToRegexpOptions: {"strict":true},
    name: "video___fr"
  }, {
    path: "/de/black-friday/handy-abos/",
    component: _b502506c,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-mobile-plans___de"
  }, {
    path: "/de/black-friday/internet-abos/",
    component: _7a3092e9,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-internet-plans___de"
  }, {
    path: "/de/special_offers/get_cashback/",
    component: _4d261eb6,
    pathToRegexpOptions: {"strict":true},
    name: "special-offers-request-cashback___de"
  }, {
    path: "/de/vergleichen/bundles/",
    component: _50bd2216,
    pathToRegexpOptions: {"strict":true},
    name: "compare-bundle___de"
  }, {
    path: "/de/vergleichen/handy/",
    component: _0df24b94,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile___de"
  }, {
    path: "/de/vergleichen/home/",
    component: _3c4dc073,
    pathToRegexpOptions: {"strict":true},
    name: "compare-home___de"
  }, {
    path: "/en/black-friday/internet-plans/",
    component: _7a3092e9,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-internet-plans___en"
  }, {
    path: "/en/black-friday/mobile-plans/",
    component: _b502506c,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-mobile-plans___en"
  }, {
    path: "/en/compare/bundles/",
    component: _50bd2216,
    pathToRegexpOptions: {"strict":true},
    name: "compare-bundle___en"
  }, {
    path: "/en/compare/home/",
    component: _3c4dc073,
    pathToRegexpOptions: {"strict":true},
    name: "compare-home___en"
  }, {
    path: "/en/compare/mobile/",
    component: _0df24b94,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile___en"
  }, {
    path: "/en/special_offers/get_cashback/",
    component: _4d261eb6,
    pathToRegexpOptions: {"strict":true},
    name: "special-offers-request-cashback___en"
  }, {
    path: "/fr/black-friday/abonnements-internet/",
    component: _7a3092e9,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-internet-plans___fr"
  }, {
    path: "/fr/black-friday/abonnements-mobiles/",
    component: _b502506c,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-mobile-plans___fr"
  }, {
    path: "/fr/comparer/home/",
    component: _3c4dc073,
    pathToRegexpOptions: {"strict":true},
    name: "compare-home___fr"
  }, {
    path: "/fr/comparer/mobile/",
    component: _0df24b94,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile___fr"
  }, {
    path: "/fr/comparer/offres-groupees/",
    component: _50bd2216,
    pathToRegexpOptions: {"strict":true},
    name: "compare-bundle___fr"
  }, {
    path: "/fr/special_offers/get_cashback/",
    component: _4d261eb6,
    pathToRegexpOptions: {"strict":true},
    name: "special-offers-request-cashback___fr"
  }, {
    path: "/de/events/birthday/register/",
    component: _05648f26,
    pathToRegexpOptions: {"strict":true},
    name: "events-birthday-register___de"
  }, {
    path: "/de/handy-und-internet-abos-fuer-geschaeftskunden/fallstudie/aas/",
    component: _72752c56,
    pathToRegexpOptions: {"strict":true},
    name: "b2b-fallstudie-apartment-service___de"
  }, {
    path: "/de/handy-und-internet-abos-fuer-geschaeftskunden/fallstudie/kernstuder/",
    component: _62aba5a0,
    pathToRegexpOptions: {"strict":true},
    name: "b2b-fallstudie-kern-studer___de"
  }, {
    path: "/de/handy-und-internet-abos-fuer-geschaeftskunden/fallstudie/sanoptis/",
    component: _bbc6f19e,
    pathToRegexpOptions: {"strict":true},
    name: "b2b-fallstudie-sanoptis___de"
  }, {
    path: "/de/order/update/selfie/",
    component: _1b04b4d4,
    pathToRegexpOptions: {"strict":true},
    name: "order-update-selfie___de"
  }, {
    path: "/de/vergleichen/handy/5g-abos/",
    component: _62b3f77b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-5g-plans___de"
  }, {
    path: "/de/vergleichen/handy/abo-aktionen/",
    component: _589c50d6,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-deal-plans___de"
  }, {
    path: "/de/vergleichen/handy/ch-unlimitiert/",
    component: _c5717376,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-ch-unlimited___de"
  }, {
    path: "/de/vergleichen/handy/europa-abos/",
    component: _0db9e84b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-europe-plans___de"
  }, {
    path: "/en/compare/mobile/5g-plans/",
    component: _62b3f77b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-5g-plans___en"
  }, {
    path: "/en/compare/mobile/ch-unlimited/",
    component: _c5717376,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-ch-unlimited___en"
  }, {
    path: "/en/compare/mobile/deal-plans/",
    component: _589c50d6,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-deal-plans___en"
  }, {
    path: "/en/compare/mobile/europe-plans/",
    component: _0db9e84b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-europe-plans___en"
  }, {
    path: "/en/order/update/selfie/",
    component: _1b04b4d4,
    pathToRegexpOptions: {"strict":true},
    name: "order-update-selfie___en"
  }, {
    path: "/fr/comparer/mobile/ch-illimite/",
    component: _c5717376,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-ch-unlimited___fr"
  }, {
    path: "/fr/comparer/mobile/offres-de-plans/",
    component: _589c50d6,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-deal-plans___fr"
  }, {
    path: "/fr/comparer/mobile/plans-5g/",
    component: _62b3f77b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-5g-plans___fr"
  }, {
    path: "/fr/comparer/mobile/plans-europe/",
    component: _0db9e84b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-europe-plans___fr"
  }, {
    path: "/fr/order/update/selfie/",
    component: _1b04b4d4,
    pathToRegexpOptions: {"strict":true},
    name: "order-update-selfie___fr"
  }, {
    path: "/de/vergleichen/bundles/:slug/",
    component: _4f0680ce,
    pathToRegexpOptions: {"strict":true},
    name: "compare-bundle-slug___de"
  }, {
    path: "/de/vergleichen/handy/:slug/",
    component: _115f8e24,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-slug___de"
  }, {
    path: "/de/vergleichen/home/:slug/",
    component: _3a971f2b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-home-slug___de"
  }, {
    path: "/en/compare/bundles/:slug/",
    component: _4f0680ce,
    pathToRegexpOptions: {"strict":true},
    name: "compare-bundle-slug___en"
  }, {
    path: "/en/compare/home/:slug/",
    component: _3a971f2b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-home-slug___en"
  }, {
    path: "/en/compare/mobile/:slug/",
    component: _115f8e24,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-slug___en"
  }, {
    path: "/fr/comparer/home/:slug/",
    component: _3a971f2b,
    pathToRegexpOptions: {"strict":true},
    name: "compare-home-slug___fr"
  }, {
    path: "/fr/comparer/mobile/:slug/",
    component: _115f8e24,
    pathToRegexpOptions: {"strict":true},
    name: "compare-mobile-slug___fr"
  }, {
    path: "/fr/comparer/offres-groupees/:slug/",
    component: _4f0680ce,
    pathToRegexpOptions: {"strict":true},
    name: "compare-bundle-slug___fr"
  }, {
    path: "/de/amp/:slug?/",
    component: _b4391630,
    pathToRegexpOptions: {"strict":true},
    name: "amp-slug___de"
  }, {
    path: "/de/anbieter/:slug/",
    component: _12ff02d3,
    pathToRegexpOptions: {"strict":true},
    name: "provider-slug___de"
  }, {
    path: "/de/black-friday/:slug?/",
    component: _7eb8e54f,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-slug___de"
  }, {
    path: "/de/blogs/:slug/",
    component: _49f71896,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-slug___de"
  }, {
    path: "/de/orders/:shortUUID?/",
    component: _3aa615ea,
    pathToRegexpOptions: {"strict":true},
    name: "orders-shortUUID___de"
  }, {
    path: "/de/page/:slug?/",
    component: _0b859d1e,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___de"
  }, {
    path: "/de/partner/:slug/",
    component: _992b5b28,
    pathToRegexpOptions: {"strict":true},
    name: "partner-slug___de"
  }, {
    path: "/en/amp/:slug?/",
    component: _b4391630,
    pathToRegexpOptions: {"strict":true},
    name: "amp-slug___en"
  }, {
    path: "/en/black-friday/:slug?/",
    component: _7eb8e54f,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-slug___en"
  }, {
    path: "/en/blogs/:slug/",
    component: _49f71896,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-slug___en"
  }, {
    path: "/en/orders/:shortUUID?/",
    component: _3aa615ea,
    pathToRegexpOptions: {"strict":true},
    name: "orders-shortUUID___en"
  }, {
    path: "/en/page/:slug?/",
    component: _0b859d1e,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___en"
  }, {
    path: "/en/partner/:slug/",
    component: _992b5b28,
    pathToRegexpOptions: {"strict":true},
    name: "partner-slug___en"
  }, {
    path: "/en/provider/:slug/",
    component: _12ff02d3,
    pathToRegexpOptions: {"strict":true},
    name: "provider-slug___en"
  }, {
    path: "/fr/amp/:slug?/",
    component: _b4391630,
    pathToRegexpOptions: {"strict":true},
    name: "amp-slug___fr"
  }, {
    path: "/fr/black-friday/:slug?/",
    component: _7eb8e54f,
    pathToRegexpOptions: {"strict":true},
    name: "black-friday-slug___fr"
  }, {
    path: "/fr/blogs/:slug/",
    component: _49f71896,
    pathToRegexpOptions: {"strict":true},
    name: "blogs-slug___fr"
  }, {
    path: "/fr/operateur/:slug/",
    component: _12ff02d3,
    pathToRegexpOptions: {"strict":true},
    name: "provider-slug___fr"
  }, {
    path: "/fr/orders/:shortUUID?/",
    component: _3aa615ea,
    pathToRegexpOptions: {"strict":true},
    name: "orders-shortUUID___fr"
  }, {
    path: "/fr/page/:slug?/",
    component: _0b859d1e,
    pathToRegexpOptions: {"strict":true},
    name: "page-slug___fr"
  }, {
    path: "/fr/partenaire/:slug/",
    component: _992b5b28,
    pathToRegexpOptions: {"strict":true},
    name: "partner-slug___fr"
  }, {
    path: "/de/angebot/:type/:slug/",
    component: _e5e459fa,
    pathToRegexpOptions: {"strict":true},
    name: "offers-type-slug___de"
  }, {
    path: "/de/feedback/:type?/:orderId?/",
    component: _346e9a1a,
    pathToRegexpOptions: {"strict":true},
    name: "feedback-type-orderId___de"
  }, {
    path: "/en/feedback/:type?/:orderId?/",
    component: _346e9a1a,
    pathToRegexpOptions: {"strict":true},
    name: "feedback-type-orderId___en"
  }, {
    path: "/en/offers/:type/:slug/",
    component: _e5e459fa,
    pathToRegexpOptions: {"strict":true},
    name: "offers-type-slug___en"
  }, {
    path: "/fr/angebot/:type/:slug/",
    component: _e5e459fa,
    pathToRegexpOptions: {"strict":true},
    name: "offers-type-slug___fr"
  }, {
    path: "/fr/feedback/:type?/:orderId?/",
    component: _346e9a1a,
    pathToRegexpOptions: {"strict":true},
    name: "feedback-type-orderId___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
