export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":["de","en","fr"]},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-CH","file":"en-US.json"},{"code":"de","iso":"de-CH","file":"de-DE.json"},{"code":"fr","iso":"fr-CH","file":"fr-FR.json"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "assets/i18n/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "https://www.alao.ch",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-CH","file":"en-US.json"},{"code":"de","iso":"de-CH","file":"de-DE.json"},{"code":"fr","iso":"fr-CH","file":"fr-FR.json"}],
  localeCodes: ["en","de","fr"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: "-",
  5: "U",
  6: "S",
  7: ".",
  8: "j",
  9: "s",
  10: "o",
  11: "n",
  12: "\"",
  13: ":",
  14: "\"",
  15: ".",
  16: ".",
  17: "/",
  18: "a",
  19: "s",
  20: "s",
  21: "e",
  22: "t",
  23: "s",
  24: "/",
  25: "i",
  26: "1",
  27: "8",
  28: "n",
  29: "/",
  30: "e",
  31: "n",
  32: "-",
  33: "U",
  34: "S",
  35: ".",
  36: "j",
  37: "s",
  38: "o",
  39: "n",
  40: "\"",
  41: ",",
  42: "\"",
  43: "d",
  44: "e",
  45: "-",
  46: "D",
  47: "E",
  48: ".",
  49: "j",
  50: "s",
  51: "o",
  52: "n",
  53: "\"",
  54: ":",
  55: "\"",
  56: ".",
  57: ".",
  58: "/",
  59: "a",
  60: "s",
  61: "s",
  62: "e",
  63: "t",
  64: "s",
  65: "/",
  66: "i",
  67: "1",
  68: "8",
  69: "n",
  70: "/",
  71: "d",
  72: "e",
  73: "-",
  74: "D",
  75: "E",
  76: ".",
  77: "j",
  78: "s",
  79: "o",
  80: "n",
  81: "\"",
  82: ",",
  83: "\"",
  84: "f",
  85: "r",
  86: "-",
  87: "F",
  88: "R",
  89: ".",
  90: "j",
  91: "s",
  92: "o",
  93: "n",
  94: "\"",
  95: ":",
  96: "\"",
  97: ".",
  98: ".",
  99: "/",
  100: "a",
  101: "s",
  102: "s",
  103: "e",
  104: "t",
  105: "s",
  106: "/",
  107: "i",
  108: "1",
  109: "8",
  110: "n",
  111: "/",
  112: "f",
  113: "r",
  114: "-",
  115: "F",
  116: "R",
  117: ".",
  118: "j",
  119: "s",
  120: "o",
  121: "n",
  122: "\"",
  123: "}",
}

export const localeMessages = {
  'en-US.json': () => import('../../assets/i18n/en-US.json' /* webpackChunkName: "lang-en-US.json" */),
  'de-DE.json': () => import('../../assets/i18n/de-DE.json' /* webpackChunkName: "lang-de-DE.json" */),
  'fr-FR.json': () => import('../../assets/i18n/fr-FR.json' /* webpackChunkName: "lang-fr-FR.json" */),
}
