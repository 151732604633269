/* eslint-disable */

class EmarsysWebExtend {
  options

  constructor (options) {
    this.options = options
  }

  get _scq () {
    return window._scq
  }

  get ScarabQueue () {
    return window.ScarabQueue
  }

  get Scarab () {
    return window.Scarab
  }

  get ScarabUtil () {
    return window.ScarabUtil
  }

  get ScarabArrays () {
    return window.ScarabArrays
  }

  get ScarabModule () {
    return window.ScarabModule
  }
}

export default (ctx, inject) => {
  /**
   * @type {ModuleOptions | undefined }
   */
  const parsedOptions = JSON.parse('{"testMode":false,"disabled":false,"merchantId":"1473C465FBE40ECB"}')

  /**
   * @type ModuleOptions
   */
  const options = Object.assign({}, parsedOptions)

  if (process.client) {
    window.ScarabQueue = window.ScarabQueue || [];

    (function(id) {
      if (document.getElementById(id)) {
        return
      }
      const js = document.createElement('script')
      js.id = id
      js.src = `//cdn.scarabresearch.com/js/${parsedOptions.merchantId}/scarab-v2.js`
      const fs = document.getElementsByTagName('script')[0]
      fs.parentNode.insertBefore(js, fs)
    })('scarab-js-api')
  }

  const instance = new EmarsysWebExtend(options)

  /* eslint-enable */
  ctx.$emarsysWebExtend = instance
  inject('emarsysWebExtend', instance)
}
